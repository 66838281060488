#dashboard {
    /* padding-left: 50px !important; */
}

#dash-select-kiosk {
    width: 200px;
    padding: 5px;
}

.dash-cards {

    /* min-height: 34% !important;
    max-height: fit-content; */
    height: 350px;
    /* background-color: red; */
    /* max-height: fit-content; */


    
}
.dash{
    height: 100%;
}

.dash-card {
    /* background-color: aqua; */
    height: 100%;
    box-shadow: 0px 0px 2px #00000026;
    border-radius: 2px;
    background-color: #FFF;
    /* min-height: 174px; */
    /* background-color: #F1C40F; */
   
}

.charts {
    height: 60%;


}


.chart-card {
    /* box-shadow: 0px 0px 2px #00000026; */
    border-radius: 2px;
    background-color: white;
    /* padding: 7px 15px; */
    
}


/* ********************************* */

.text-card{
    box-shadow: 0px 0px 2px #00000026;
    border-radius: 7px;
    color: white;
}

.text-card img{
    height: 30px;
}

.kiosk-card{
    /* background-color: #EA3986; */
    background-image: linear-gradient(to right,#4972F5,#89A3FF);
}

.product-card{
    /* background-color: #F1C40F; */
    background-image: linear-gradient(to right,#7858BA,#A678FF);
}

.employee-card{
    /* background-color: #18A4E1; */
    background-image: linear-gradient(to right,#C56F37,#FBAA68);
}

.stats-card{
    /* background-color: #F98925; */
    background-image: linear-gradient(to right,#0FC226,#43F65B);
}



@media screen and (max-width:576px){
    .perfomance-chart{
        width: 400px;
        
    }
    .perfomance-chart-div {
        overflow-x: auto;
        
        
    }
    

    /* .charts {
        min-height: 500px;
        height: fit-content;
        
    
    }
    .chart-div{
        height: 50%;
        
        
    }
    .chart-card{
        height: 50% !important;
    } */

    .dash-cards {

        /* min-height: 34% !important;
        max-height: fit-content; */
        /* height: 700px; */
        /* background-color: red; */
        /* max-height: fit-content; */
    
    
        
    }
    .dash{
        height: 50%;
    }
    .dash-card{
        height: 50%;
    }
    /* .chart-card{
        height: 50%;
    } */
    
}




