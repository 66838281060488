.table-with-pagination {
    background-color: #FFFFFF;
    /* box-shadow: 2px 3px 10px #7777771A; */
border-radius: 3px;
/* border: none !important; */
}

.table-with-pagination div{
    overflow-y: auto;
}

.table-with-pagination .dataTables_wrapper{
    overflow: hidden;
}

.table-with-pagination table{
    /* background-color: white; */
    border: 1px solid white;
    /* border: none !important; */
    box-shadow: none;
    /* border-collapse: collapse; */
    border-collapse:separate;
    border-spacing:0 4px;
    background-color: #F8F9FD;
    
}
.table-with-pagination table thead{
    /* border: 2px solid #C3C5CB; */
    background-color: #E9EAEC;
    
}
.table-with-pagination table thead th{
    font-size: 16px;
    font-family: Segoe UI Semibold;
    text-transform: capitalize;
}
.table-with-pagination td{
    text-transform: capitalize;
}
.table-with-pagination tr{
    text-align-last: start !important;
    
}

 .table-with-pagination td{
    width: 10px !important;
    max-width: 10px !important;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}
tr{
    border-spacing:10px 15px !important
}
.table-with-pagination td{
    padding: 13px 10px !important;
    margin: 3px 0 !important;
    
}
.table-with-pagination .dataTables_wrapper .row:last-child{
    background-color: #F8F9FD;
}

.table-with-pagination .bs-select{
    margin-left: 30px;
}

.table-with-pagination table td,.table-with-pagination table th{
    /* border: 1px solid #DDDFE1; */
    font-size: 14px;
    vertical-align: middle !important;
    border: none !important;
    /* width: 50px !important; */
}

.table-with-pagination tbody tr:nth-child(even) {
    /* Add your custom styling for even rows (2, 4, 6, 8) here */
    background-color: #E9EAEC;
  }
  
  .table-with-pagination tbody tr:nth-child(odd) {
    /* Add your custom styling for odd rows (1, 3, 5, 7, etc.) here */
    background-color: white;
  }

.table-with-pagination table th{
    padding: 10px;
    /* text-align: center !important; */
}

.table-with-pagination li{
    padding: 3px;
}

.table-with-pagination li a{
    border: 1px solid #C3C5CB;
    border-radius: 5px;
}

.table-with-pagination .table-foot{
    display: none !important;
}

.table-with-pagination .page-item.active .page-link{
    background-color: #232020 !important;
}

.table-with-pagination .detail-btn{
    /* box-shadow: 0px 0px 2px #00000029; */
    border-radius: 2px;
    background-color: white;
    background-image: linear-gradient(#fc506e,#dfa14e);
    color: white;
    height: 22px;
    font-size: 12px;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {
   
    height: 100%;
    transform: translateY(55%);
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
   
    height: 100%;
    transform: translateY(55%);
}



