.multi-select-div{
    height: 300px;
    display: flex;
    flex-direction: column;
    
}
.multi-select-div p{
    border-radius: 6px 6px 0 0;
}
.move-icon-div{
    /* height: 25px; */
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    background-color: rgba(56, 47, 47, 0.5);
    padding: 15px 5px;
    border-radius: 50px;
}
.move-icon-div button{
    border: none;
    background-color: transparent;
    padding: 3px 0;
}
.option-btn{
    border: none;
    background-color: rgba(56, 47, 47, 0.9);
    color: white;
    padding: 2px 15px;
    border-radius: 20px;
    margin-top: 10px;
    /* margin-bottom: 10px; */
}

.delete-btn{
    border: 1px solid rgba(165, 42, 42, 0.5);
    padding: 3px 8px;
    border-radius: 3px;
}

.tab-box{
    border: 1.5px solid black;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    height: 70px;
    width: 150px;
    margin: 0 5px;
    /* padding-right: 20px; */
}