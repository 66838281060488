.black-btn{
    background-color: #232529;
    border: none;
    box-shadow: 0px 0px 2px #00000026;
    border: 0.5px solid #232529;
    border-radius: 3px;
    color:white;
    /* height: 40px; */
    width: 180px;
    max-width: 100%;
}