.login{
    background-color: #232529;
    min-height:  720px;
    height: 100vh;
    max-height: fit-content;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    color: white;
}

.left-circle-img{
    width: 300px;
    position: absolute;
    transform: translateX(-27%) translateY(-8.5%);
    /* top: 0; */
}
.right-circle-img{
    width: 350px;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(40%) translateY(20%);
}



.login .input input{
    width: 100%;
    border: 1px solid #B4B4B4;
    border-radius: 2px;
    background-color: #B4B4B4;
    
}

#eye{
    position: absolute;
  /* top: 50%; 
  left: 50%; */
  top: 0;
  right: 0;
  transform: translate(-170%, 60%); 
  cursor: pointer;
  opacity: 0.5;
}

#remember-checkbox{
    margin-right: 7px;
}
.sign-in-btn{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    border: none;
    color: black;
    
}