.item-div {
    border: 1px solid #232529;
    border-radius: 2px;
    padding: 7px 15px;
}
.clock-div{
    box-shadow: 0px 0px 5px #00000019;
    border-radius: 2px;
    padding: 11px 10px;
}
.time-circle{
    display: flex;
    height: 160px;
    width: 160px;
    border: 15px solid #ED795E;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.activity-div{
    box-shadow: 0px 0px 5px #00000019;
    border-radius: 2px;
    opacity: 1;
}

.clock-stauts-div{
    border-left: 3px solid #463B3B;
    padding-left: 8px;
    position: relative;
}

.circle{
    display: flex;
    height: 10px;
    width: 10px;
    background-color: #463B3B;
    border-radius: 50%;
    position: absolute;
    transform: translateX(-140%) translateY(80%);
    
}