.switch-button{
    padding: 0 5px;
    /* height: 100%; */
    /* background-color: red; */
}
.switch-button input{
    display: none;
}

.switch-button label {
    background-color: white;
    box-shadow: 0px 0px 2px #00000026;
    border-radius: 2px;
    padding: 5px 7px 6px 7px;
    max-width: 100%;
    /* height: 100%; */
    margin-bottom: 0px;
    height: 100%;
    /* margin: 0 5px; */
}
.switch-button label:hover{
    cursor: pointer;
}

.switch-button input:checked + label{
    background-color:#232529 ;
    color: white;
}