:root{
  --sub-sidebar-width:0px;
  --sub-sidebar :none;
  --sub-sidebar-position :static;
  --left:0px;
  --sub-sidebar-left:65px;
}


#main{
  height: calc(100vh - 60px);
  display: flex;
}

.content-div{
  width: calc(100% - var(--left) - 65px);
  height: 100%;
  /* min-height: calc(720px - 60px); */
  background-color: #F8F9FD;
  position: relative;
  left: calc(var(--left) + 65px);
  top: 60px;
  /* max-height: fit-content; */
  /* overflow-y: auto; */
  min-height: 100vh;
  height: fit-content;

  /* width: 1000px;
  height: 1000px; */
  /* transform-origin: left top; */


  
 

}

.yellow-color{
  color: #ED795E;
}


.h-1{
  height: 20px !important;
}





/* .font-1{
  font-size: 48px;
}

.font-2{
  
  font-size: 32px;
}

.font-3{
  font-size: 24px;
}

.font-4{
  font-size:18px ;
}

.font-5{
  font-size: 16px;
}

.font-6{
  font-size: 14px;
}

.font-7{
  font-size: 12px;
}

.font-8{
  font-size: 10px;
} */


.font-1{
  font-size: 16px;
}

.font-2{
  font-size: 14px;
}

.semi-bold{
  /* font-weight: 600; */
  /* font-weight: bold; */
  /* opacity: 0.8; */
  font-family: Segoe UI Semibold;
}



.mobile{
  display: none !important;
}



/* @media screen and (width: 1500px) {
  .font-1{
    font-size: 56px;
  }
  
  .font-2{
    
    font-size: 40px;
  }
  
  .font-3{
    font-size: 32px;
  }
  
  .font-4{
    font-size:26px ;
  }
  
  .font-5{
    font-size: 24px;
  }
  
  .font-6{
    font-size: 22px;
  }
  
  .font-7{
    font-size: 20px;
  }
  
  .font-8{
    font-size: 18px;
  }
} */


@media screen and (max-width:576px){
  /* .font-1{
    font-size: 42px;
  }
  
  .font-2{
    
    font-size: 26px;
  } */
  
  /* .font-3{
    font-size: 18px;
  }
  
  .font-4{
    font-size:12px ;
  }
  
  .font-5{
    font-size: 10px;
  }
  
  .font-6{
    font-size: 8px;
  }
  
  .font-7{
    font-size: 6px;
  }
  
  .font-8{
    font-size: 4px;
  } */
  .reverse-col{
    flex-direction: column-reverse;
}
.content-div{
  overflow-y: auto;
}

  .desktop{
    display: none;
  }
  .mobile{
    display: flex  !important;
    margin: 10px 0;
  }
}

