.item-box{
    box-shadow: 0px 0px 3px #00000040;
}

.mobile-item-box-div{
    box-shadow: 0px 0px 3px #00000033;
    border-radius: 2px;
    padding: 5px !important;
}


@media screen and (min-width: 1500px) {
    .sales .switch-button label{
        width: 190px !important;
    }

    .cancel-sales .switch-button label{
        width: 190px !important;
    }


}


@media screen and (max-width:576px){
    
}