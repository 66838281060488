.img-input-div{
    /* background-color: red; */
    display: flex;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    align-items: center;
    position: relative;
}
.img-input-div img{
    height: 25px;
}
.img-input-div span{
    opacity: 0.5;
}
.div-input{
    /* pos */
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
}
.img-input-div input{
    /* position: absolute; */
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
}

