.file-div{
    border: 1px solid #232529;
    border-radius: 2px;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    /* height: 80px; */
}

.file-div span{
    opacity: 50%;
}

.input-div{
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    top: 0;
    left: 0;
    /* display: none; */
}
.input-div input{
    opacity: 0;
    
}


#product-img-prview{
    position: absolute;
    top: 0;
    height: 100%;
}
