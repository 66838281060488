.top-bar{
    height: 60px;
    box-shadow: 0px 1px 3px #00000040;
    border: 0.5px solid #23252940;
    padding-left: 20px !important;
    padding-right: 20px !important;
    /* position: absolute; */
    position: fixed;
    width: 100% !important;
    z-index: 99;
    background: white;
}
.topbar-logo{
    display: flex;
}

.heading{
    padding-left: 50px;
}

.notification-div{
    position: relative;
    /* background-color: red; */
    /* width: fit-content; */
}
.bell-img{
    position: absolute;
    right: 0;
}

.number{
    position: absolute;
    top: 0px;
    right: -5px;
    background-color: red;
    /* line-height: 0; */
    min-height: 15px;
    aspect-ratio: 1/1;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
}


@media screen and (max-width:576px){
    .top-bar{
        width: calc(100% - 65px) !important;
        left: 65px;
    }
    .topbar-logo{
        display: none !important;
    }
}