.rs-picker-daterange-menu{
    /* left: 0 !important; */
}
.rs-picker-toggle {
    padding: 6px 15px !important;
}

.rs-picker-toggle-value{
    color: black !important;
}

.field{
    /* height: 100% !important; */
    /* background-color: red; */
}