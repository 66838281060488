input[name=sale-type]{
    /* -webkit-appearance: none;
  -moz-appearance: none; */
  /* appearance: none; */

 
  height: 15px;
  aspect-ratio: 1/1;
  margin-right: 5px;

  
}

.sale-input-div{
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 5px;
  background-color: white;
  /* background-color: red; */
  height: fit-content;
  padding: 7px;
}

.total-price{
  
  padding:4px 0;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 2px;
  width: 200px;
}

.cart-div{
  border-radius: 2px;
  background-color: #FFFFFF;
}
.cart{
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 2px;
}

.img-btn:hover{
  cursor: pointer;
}

#clear-cart{
  background-color: transparent;
  border: 1px solid #232529;
  border-radius: 2px;
}



  