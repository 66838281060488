.download-button{
    background-color: black;
    padding: 4px;
    box-shadow: 0px 0px 2px #00000026;
    border-radius: 3px;
    border: none;
    aspect-ratio: 1/1;
    /* min-width: 35px; */
    height: 100%;
    
}