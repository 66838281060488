
.table{
  /* min-width: 79vw; */
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.table tr{
  /* text-align-last: center; */
}



.table{
  background-color: white;
  overflow-x: auto;
}
.table-striped{
  margin: 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

.editing-name{
  max-width: 60px !important;
  height: 30px;
}

.tabheader th{
      font-size: 13px;
      font-weight: 100;
      background-color: rgb(70, 59, 59);
      color: white;
      text-align: center;
      width: 100px;
}
/* .table-body-height{
  height: 300px !important;
  overflow-y: scroll;
}
.production-table{
  height: 500px !important;
  background-color: red;
} */
.react-bootstrap-table {
  max-height: 65vh !important;
  overflow-y: auto;
}

.react-bootstrap-table thead{
  position: sticky;
  top: 0;
  font-size: '13px';
  font-weight: 100;
  background-color : #232529;
  color: white;
  z-index: 1;
  
}
.react-bootstrap-table thead tr th{
  width: 100px;
}
.react-bootstrap-table tbody tr td{
  vertical-align:middle !important;
}

.react-bootstrap-table-editing-cell{

  display: flex;
  justify-content: center;

}

.react-bootstrap-table th:first-child
{
  position:sticky;
  left:0px;
  background-color:#232529;
  z-index: 1;
}
.react-bootstrap-table td:first-child
{
  position:sticky;
  left:0px;
  background-color:#232529;
  color:white
}

.producton-no-lable{
  box-shadow: 0px 0px 2px #00000026;
  border: 0.5px solid #232529;
  border-radius: 2px;
  padding: 7px 15px;
}

label:hover{
  cursor: pointer;
}

.production-page input:checked +label{
  background-color: #232529;
  color: white;
  
}

