.loader-div{
    height:calc(100vh - 65px - 30px);
    width:100%;
    /* position:'absolute'; */
    /* background-color: rebeccapurple; */
}

#loader{
    width:7%;
    min-width:50px;
    /* scale:'transformY:"-7%"' */
}